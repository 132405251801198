import { SVGProps } from 'react'

export const Arrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    {...props}
  >
    <path d="M12 6.9282L0 13.8564L6.05683e-07 0L12 6.9282Z" fill="currentColor" />
  </svg>
)
