import { SVGProps } from 'react'

export const NoAssets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="109"
    height="97"
    viewBox="0 0 109 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M26.0918 60.8774H63.3367" stroke="#52525B" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M26.0918 68.2168H43.7449" stroke="#52525B" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M85.5204 15.7622H88.5C91.8137 15.7622 94.5 18.4485 94.5 21.7622V75.8177C94.5 79.1314 91.8137 81.8177 88.5 81.8177H20.5C17.1863 81.8177 14.5 79.1314 14.5 75.8177V21.7622C14.5 18.4485 17.1863 15.7622 20.5 15.7622H67.602"
      stroke="#52525B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M75.3721 33.0961L74.9902 32.8703L74.6084 33.0961L68.1175 36.9355C67.9509 37.0341 67.7402 36.914 67.7402 36.7203V15.5C67.7402 14.2574 68.7476 13.25 69.9902 13.25H79.9902C81.2329 13.25 82.2402 14.2574 82.2402 15.5V36.7203C82.2402 36.914 82.0296 37.0341 81.863 36.9355L75.3721 33.0961Z"
      stroke="#65A30D"
      strokeWidth="1.5"
    />
  </svg>
)
