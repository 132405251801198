export const createQueryParams = (
  params?: Record<string, string | number | boolean | Array<string>>,
): string => {
  if (!params) {
    return ''
  }

  const queryParams = new URLSearchParams()
  Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => !!value)
    .forEach(([key, value]) => {
      queryParams.set(key, String(value))
    })

  return queryParams.toString()
}

export const withQueryParams = (
  url: string,
  queryParams?: Record<string, string | number | boolean | Array<string>>,
) => {
  const queryString = createQueryParams(queryParams)
  return queryString ? `${url}?${queryString}` : url
}

export const RouteNames = {
  boir: 'boir',
  quiz: 'quiz',
  error: 'error',
  api: {
    uploadBoirFile: 'uploadBoirFile',
    webhooks: {
      stripe: 'stripe',
    },
  },
}

export const Routes = {
  Home: () => '/',
  Boir: () => `/${RouteNames.boir}`,
  Quiz: () => `/${RouteNames.quiz}`,
  Error: () => `/${RouteNames.error}`,
  API: {
    UploadBoirFile: `/api/${RouteNames.api.uploadBoirFile}`,
    Webhooks: {
      Stripe: `/api/webhooks/${RouteNames.api.webhooks.stripe}`,
    },
  },
}

export const HomepageSections = {
  whatIsBoir: 'whatIsBoir',
  howToFile: 'howToFile',
  whyUs: 'whyUs',
  faq: 'faq',
  contact: 'contact',
}
