import React from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'lib/utils'
import { Icon } from 'components/ui/Icon'

const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center rounded-md gap-2 text-sm leading-[1.2] font-bold',
    'focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus:outline-none hover:border-surface-400',
    'disabled:pointer-events-none disabled:opacity-50 transition duration-150 ease-[cubic-bezier(0.4, 0.0, 0.2, 1)]',
  ),
  {
    variants: {
      variant: {
        ghost: 'text-primary bg-transparent',
        icon: 'bg-transparent rounded-lg focus:outline-primary-600 focus-visible:ring-0 focus-visible:ring-offset-0 active:bg-secondary-200 active:outline-none [&:not(:focus-visible)]:outline-none',
        link: 'underline rounded-sm',
        primary: 'bg-process text-surface hover:bg-process-700',
        pill: 'bg-secondary-0 font-normal border border-secondary-300 hover:bg-secondary-50 hover:border-secondary-400 focus-visible:outline-primary-600',
        secondary: 'text-primary hover:bg-surface-50',
      },
      size: {
        base: 'px-5 py-3 text-[0.9375rem] leading-[1.2]',
        sm: 'px-4 py-2.5',
        xs: 'p-2',
      },
      isActive: { true: 'bg-process' },
      disabled: { true: 'pointer-events-none opacity-50' },
    },
    compoundVariants: [
      {
        variant: 'link',
        class: 'px-0',
      },
      {
        variant: 'pill',
        isActive: true,
        class: 'border-transparent hover:border-transparent hover:bg-primary-300',
      },
      {
        variant: 'pill',
        size: 'base',
        class: 'px-3 py-[0.5625rem]',
      },
      {
        variant: 'pill',
        size: 'sm',
        class: 'py-[0.4375rem]',
      },
      {
        variant: ['secondary'],
        size: ['base', 'sm'],
        class:
          'outline outline-1 outline-secondary-300 focus:outline focus:outline-1 focus:outline-secondary-300 hover:outline-gray-400 font-semibold',
      },
      {
        variant: ['ghost', 'icon'],
        size: ['base', 'sm', 'xs'],
        class: 'hover:bg-surface-100',
      },
      {
        variant: 'icon',
        size: ['base', 'sm'],
        class: 'p-2',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'base',
    },
  },
)

interface CommonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  ref?: React.ForwardedRef<HTMLButtonElement>
  isActive?: boolean
  disabled?: boolean
}

type ConditionalProps =
  | {
      variant?: 'circleShadow' | 'circleFlat'
      isLoading?: never
      leftIcon?: never
      rightIcon?: never
      size?: 'base' | 'sm'
    }
  | {
      variant?: 'primary' | 'secondary' | 'ghost'
      isLoading?: boolean
      leftIcon?: React.ReactNode
      rightIcon?: React.ReactNode
      size?: 'base' | 'sm' | 'xs'
    }
  | {
      variant?: 'pill'
      isActive?: boolean
      isLoading?: boolean
      leftIcon?: React.ReactNode
      rightIcon?: React.ReactNode
      size?: 'base' | 'sm'
    }
  | {
      variant?: 'link' | 'tile' | 'tileOutline'
      isLoading?: never
      leftIcon?: React.ReactNode
      rightIcon?: React.ReactNode
      size?: 'base' | 'sm'
    }
  | {
      variant?: 'icon'
      isLoading?: boolean
      leftIcon?: never
      rightIcon?: never
      size?: 'base' | 'xs' | 'sm'
    }

export type ButtonProps = CommonProps & ConditionalProps

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      isLoading,
      leftIcon,
      rightIcon,
      size,
      variant,
      isActive,
      type = 'button',
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, isActive, className }))}
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={isLoading || disabled}
        {...props}
      >
        {isLoading && <Icon.Loader2 size={20} className="animate-spin" />}
        {leftIcon}
        <Slottable>
          {variant === 'icon'
            ? React.cloneElement(children as React.ReactElement, {
                size: size === 'sm' || size === 'xs' ? 20 : 24,
                style: { display: isLoading ? 'none' : 'block' },
              })
            : children}
        </Slottable>
        {rightIcon}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
