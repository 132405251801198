import { SVGProps } from 'react'

export const Home = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M12.0396 17.5H16.1182C16.6814 17.5 17.1379 17.0408 17.1379 16.4743V11.0734C17.1379 10.7416 17.4053 10.4726 17.7352 10.4726C18.2673 10.4726 18.5338 9.82545 18.1575 9.44696L10.7213 1.96703C10.3231 1.56649 9.67752 1.56649 9.27933 1.96703L1.84313 9.44696C1.46686 9.82545 1.73335 10.4726 2.26549 10.4726C2.59536 10.4726 2.86278 10.7416 2.86278 11.0734V16.4743C2.86278 17.0408 3.3193 17.5 3.88243 17.5H7.96103M12.0396 17.5V13.5496C12.0396 12.9831 11.5831 12.5239 11.02 12.5239H8.98068C8.41754 12.5239 7.96103 12.9831 7.96103 13.5496V17.5M12.0396 17.5H7.96103"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
)
