/* eslint-disable no-restricted-imports */
import { cn } from 'lib/utils'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'

export type LinkProps = React.PropsWithChildren<
  NextLinkProps & { target?: string; className?: string }
>

export const Link = ({ children, className, ...props }: LinkProps) => (
  <NextLink className={cn('focus:outline-none', className)} {...props}>
    {children}
  </NextLink>
)
