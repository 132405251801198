import { SVGProps } from 'react'

export const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4200_9377)">
      <mask id="mask0_4200_9377" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path d="M0 0H20V20H0V0Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4200_9377)">
        <path
          d="M5 3.33357V16.6669C4.99996 16.8152 5.03948 16.9608 5.11448 17.0887C5.18949 17.2166 5.29726 17.3222 5.42669 17.3945C5.55611 17.4669 5.70249 17.5034 5.85074 17.5003C5.99898 17.4972 6.14371 17.4546 6.27 17.3769L17.1033 10.7102C17.2247 10.6357 17.3249 10.5313 17.3944 10.407C17.4639 10.2827 17.5004 10.1426 17.5004 10.0002C17.5004 9.85782 17.4639 9.71779 17.3944 9.5935C17.3249 9.4692 17.2247 9.36479 17.1033 9.29024L6.27 2.62357C6.14371 2.54587 5.99898 2.50328 5.85074 2.50018C5.70249 2.49709 5.55611 2.5336 5.42669 2.60595C5.29726 2.67831 5.18949 2.78388 5.11448 2.91179C5.03948 3.03969 4.99996 3.18529 5 3.33357Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4200_9377">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
