import { SVGProps } from 'react'

export const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="10.25" stroke="currentColor" strokeWidth="1.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8955 7.90094H15V6H13.2738C12.5088 6.00084 11.7754 6.27762 11.2345 6.76964C10.6936 7.26166 10.3893 7.92874 10.3884 8.62456V9.80188H9V11.7028H10.3932V18H12.4831V11.7028H14.5876L15 9.80188H12.4831V8.27543C12.4847 8.17646 12.5287 8.08196 12.6057 8.01203C12.6827 7.9421 12.7867 7.90225 12.8955 7.90094Z"
      fill="currentColor"
    />
  </svg>
)
