import { SVGProps } from 'react'

export const NoFavoriteResources = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109"
    height="96"
    viewBox="0 0 109 96"
    fill="none"
    {...props}
  >
    <path d="M27.0918 56.3774H64.3367" stroke="#52525B" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M27.0918 63.7168H44.7449" stroke="#52525B" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M85.5204 15.262H88.5C91.8137 15.262 94.5 17.9483 94.5 21.262V75.3175C94.5 78.6312 91.8137 81.3175 88.5 81.3175H20.5C17.1863 81.3175 14.5 78.6312 14.5 75.3175V21.262C14.5 17.9483 17.1863 15.262 20.5 15.262H55.602"
      stroke="#52525B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M70.585 29.2397L70.1761 28.8989C61.3851 21.7435 59 19.2221 59 15.1332C59 11.7259 61.7259 9 65.1332 9C67.9273 9 69.4946 10.5674 70.585 11.794C71.6754 10.5674 73.2427 9 76.0368 9C79.4441 9 82.17 11.7259 82.17 15.1332C82.17 19.2221 79.7849 21.7435 70.9939 28.8989L70.585 29.2397Z"
      stroke="#65A30D"
      strokeWidth="1.5"
    />
  </svg>
)
